import { CallbackType } from '@forgerock/javascript-sdk/lib';
import { updateStageAction } from 'components/Login/actions';
import { MESSAGE_TRY_NEW_OTP } from 'components/Login/constants';
import nextStep from './handleStep';
import executeHiddenCallbackForOTPAutomatedTesting from './executeHiddenCallbackForOTPAutomatedTesting';
import { getCallbackOfTypeSafely, getCallbackWithMessageSafely } from '../authTreeUtils';

const sendOtpForAddNumberOrAddBackupEmailFlow = async (dispatch, step, otp) => {
  let stage = step;

  const passwordCallback = getCallbackOfTypeSafely(step, CallbackType.PasswordCallback);
  const hiddenValueCallback = getCallbackOfTypeSafely(step, CallbackType.HiddenValueCallback);

  if (
    getCallbackOfTypeSafely(step, CallbackType.TextOutputCallback) &&
    getCallbackWithMessageSafely(step, MESSAGE_TRY_NEW_OTP)
  ) {
    dispatch(updateStageAction(step));
  } else if (passwordCallback) {
    passwordCallback.setPassword(otp);
    stage = await nextStep(dispatch, step);
  } else if (hiddenValueCallback) {
    hiddenValueCallback.setInputValue('output');
    const secondStep = await nextStep(dispatch, step);
    // handling for users in automated testing group
    const finalStep = await executeHiddenCallbackForOTPAutomatedTesting(dispatch, secondStep);
    const finalStepPasswordCallback = finalStep.getCallbackOfType(CallbackType.PasswordCallback);
    finalStepPasswordCallback.setPassword(otp);
    stage = await nextStep(dispatch, finalStep);
  }
  return stage;
};

export default sendOtpForAddNumberOrAddBackupEmailFlow;
