import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Card, BUTTON_VARIANT, H3 } from '@manulife/mux';
import FormElement from 'components/common/FormElement';
import { afterThreeTries } from 'components/VerifyPassword/actions';
import styled from 'styled-components';
import {
  FormattedRichMessage,
  useRichIntl,
  useRealm,
  usePathBuilder,
  useCIAMContextParam,
  ContentPage,
  customStyles,
  CIAMLinkButton,
} from 'ciam-self-service-shared';
import initAsyncAction from 'utils/loginAuthnTree/initAsyncActionImpl';
import { Redirect } from 'react-router';
import { FormattedMessage } from 'react-intl';
import Page, { AUTH_TYPE } from '../../common/Page';
import { AUTH_TREE_REALM_MAPPING } from '../constants';
import useUniversalLink from '../../../hooks/useUniversalLink';
import { MainContainer, IntroductionPageCardWrapper } from './styledComponents';
import useOnDemandOtpRedirect from '../../OnDemandOTP/useOnDemandOtpRedirect';

export const MessageWrapper = styled.div`
  text-align: start;

  & button {
    align-items: start;
  }
`;

function IntroductionPage() {
  const realm = useRealm();
  const dispatch = useDispatch();
  const universalLink = useUniversalLink();
  const richIntl = useRichIntl();
  const pathBuilder = usePathBuilder();
  const brand = useCIAMContextParam('brand');
  const mainBackButtonFlag = sessionStorage.getItem('ciam.backButton-otp');
  const addNumberBackButtonFlag = sessionStorage.getItem('ciam.addNumberbackButton-otp');
  const addBackupEmailbackButtonFlag = sessionStorage.getItem('ciam.addBackupEmailbackButton-otp');
  const isOnDemand = useOnDemandOtpRedirect();

  useEffect(() => {
    if (!mainBackButtonFlag && !addNumberBackButtonFlag && !addBackupEmailbackButtonFlag && !isOnDemand) {
      initAsyncAction(dispatch, AUTH_TREE_REALM_MAPPING[realm], realm, brand);
      dispatch(afterThreeTries());
    }
  }, [mainBackButtonFlag, addNumberBackButtonFlag, addBackupEmailbackButtonFlag, realm, brand, dispatch, isOnDemand]);

  if (universalLink) {
    window.location.href = universalLink;
    return null;
  }

  if (mainBackButtonFlag) {
    return <Redirect to={pathBuilder('/otp')} />;
  }
  if (addNumberBackButtonFlag) {
    return <Redirect to={pathBuilder('/addnumbermfa')} />;
  }

  if (isOnDemand) {
    return null;
  }

  return (
    <Page
      title={richIntl.formatMessage({ id: 'introduction.page.title' })}
      authType={AUTH_TYPE.UNAUTHENTICATED}
      heading={richIntl.formatMessage({ id: 'introduction.page.heading' })}
    >
      <ContentPage
        title={richIntl.formatMessage({ id: 'introduction.page.title' })}
        heading={<FormattedRichMessage id="introduction.page.heading" />}
        contentColumns={9}
      >
        <H3>
          <FormattedRichMessage id="introduction.page.subheading" />
        </H3>
        <FormattedRichMessage id="introduction.page.inboundManulifeId.para" />
        <MainContainer>
          <IntroductionPageCardWrapper>
            <div>
              <Card>
                <Card.Content>
                  <H3>
                    <FormattedRichMessage id="introduction.page.haveid" />
                  </H3>
                  <p>
                    <FormattedRichMessage id="introduction.page.haveidbeginjourney" />
                  </p>
                  <FormElement spacing={2}>
                    <CIAMLinkButton to="/signin" customStyle={customStyles.signInButton}>
                      <FormattedMessage id="common.signin" />
                    </CIAMLinkButton>
                  </FormElement>
                </Card.Content>
              </Card>
            </div>
            <div>
              <Card>
                <Card.Content>
                  <H3>
                    <FormattedRichMessage id="introduction.page.donthaveid" />
                  </H3>
                  <p>
                    <FormattedRichMessage id="introduction.page.idadvantages" />
                  </p>
                  <FormElement spacing={2}>
                    <CIAMLinkButton to="/register" variant={BUTTON_VARIANT.SECONDARY} customStyle={customStyles.button}>
                      <FormattedRichMessage id="aboutmanulifeid.page.gosignup.button" />
                    </CIAMLinkButton>
                  </FormElement>
                </Card.Content>
              </Card>
            </div>
          </IntroductionPageCardWrapper>
        </MainContainer>
      </ContentPage>
    </Page>
  );
}

export default IntroductionPage;
