import React from 'react';
import { FormattedRichMessage, CIAMLinkButton, ContentPage, ErrorAlert, useRealm } from 'ciam-self-service-shared';
import styled from 'styled-components';
import Page from 'components/common/Page';
import { useSelector } from 'react-redux';
import AnalyticsDataLayer from '../../../common/Analytics';
import { EVENT_REGISTER_ERROR } from '../../constants';
import errorvalue from '../../../../translations/errors.json';
import { useIntl } from 'react-intl';

const InstructionsWrapper = styled.div`
  margin: 2.25rem 0;
`;

export default function ErrorPage() {
  const realm = useRealm();
  let error = useSelector((state) => state?.registrationInfo?.error);
  const userName = sessionStorage.getItem(`userName-${realm}`);
  const intl = useIntl();

  if (error == null) {
    error = userName == null ? errorvalue.D_703 : errorvalue.D_702;
  } else if (error.code === 0) {
    error = errorvalue.D_701;
  }

  return (
    <Page title={intl.formatMessage({ id: 'common.error' })}>
      <AnalyticsDataLayer pageName={EVENT_REGISTER_ERROR} error="SignupError" />
      <ContentPage>
        <ErrorAlert error={error} />
        <InstructionsWrapper>
          <FormattedRichMessage id="signupVerify.error.instructions" />
        </InstructionsWrapper>
        <CIAMLinkButton to="/register">
          <FormattedRichMessage id="signupVerify.error.button" />
        </CIAMLinkButton>
      </ContentPage>
    </Page>
  );
}
