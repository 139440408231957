import { asyncWrapper } from './async';

const removeSessionStorageAttributes = async () => {
  sessionStorage.removeItem('ciam.backButton-otp');
  sessionStorage.removeItem('ciam.addNumberbackButton-otp');
  sessionStorage.removeItem('ciam.addBackupEmailbackButton-otp');
  sessionStorage.removeItem('isOTPGeneratedMultipleTimes');
  sessionStorage.removeItem('context');
};
export default asyncWrapper(removeSessionStorageAttributes);
