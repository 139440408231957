import React from 'react';
import Page, { AUTH_TYPE } from '../../components/common/Page';
import { useDispatch, useSelector } from 'react-redux';
import AnalyticsDataLayer from '../common/Analytics';
import { CONNECT_PRODUCTS } from './constants';
import { FormattedMessage, useIntl } from 'react-intl';
import { BUTTON_VARIANT } from '@manulife/mux';
import {
  useCIAMHistory,
  useRealm,
  useCIAMContextParam,
  ContentPage,
  FormattedRichMessage,
  CoBrandingButton,
  customStyles,
  useRichIntl,
} from 'ciam-self-service-shared';
import FormElement from '../../components/common/FormElement';
import useGoToUrl from '../../hooks/useGoToUrl';
import useAsyncCallback from '../../hooks/useAsyncCallback';
import successRedirection from '../../utils/loginAuthnTree/common/successRedirection';

const ConnectProducts = () => {
  const intl = useRichIntl();
  const userContext = useSelector((state) => state.loginInfo.userContext);
  const firstName = userContext.givenName;
  const dispatch = useDispatch();
  const history = useCIAMHistory();
  const gotoURI = useGoToUrl();
  const { locale } = useIntl();
  const realm = useRealm();
  const brand = useCIAMContextParam('brand');
  const stage = useSelector((state) => state.loginInfo.stage);

  const connectProducts = useAsyncCallback(async () => {
    successRedirection(dispatch, { history, gotoURI, locale, realm, brand, finalStep: stage });
  }, [brand, dispatch, gotoURI, history, locale, realm, stage]);
  return (
    <Page
      title={intl.formatMessage({ id: 'connectpage.title' })}
      heading={intl.formatMessage({ id: 'connectpage.heading' })}
      authType={AUTH_TYPE.UNAUTHENTICATED}
    >
      <AnalyticsDataLayer pageName={CONNECT_PRODUCTS} />
      <ContentPage heading={<FormattedRichMessage id="connectpage.heading" values={{ firstName }} />}>
        <p>
          <FormattedRichMessage id="connectpage.why" />
        </p>
        <FormElement spacing={2}>
          <CoBrandingButton
            variant={BUTTON_VARIANT.PRIMARY}
            customStyle={customStyles.button}
            onClick={connectProducts}
            id="connect-products-btn"
          >
            <FormattedMessage id="connectpage.button" />
          </CoBrandingButton>
        </FormElement>
      </ContentPage>
    </Page>
  );
};

export default ConnectProducts;
