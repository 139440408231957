import { CallbackType } from '@forgerock/javascript-sdk/lib';
import { updateOTPRequestedState, updateOtpCountAction, updateErrorCode } from 'components/Login/actions';
import {
  MESSAGE_OTP_EXPIRED_ADD_NUMBER,
  PROMPT_OTP_CHOICES,
  MESSAGE_OTP_RETRY_LIMIT,
} from 'components/Login/constants';
import removeSessionStorageAttributes from 'utils/removeSessionStorageAttributes';
import { updateErrorInState } from 'ciam-self-service-shared';
import nextStep from './handleStep';
import { checkStep } from 'utils/authTree/helpers';
import chooseResendOrRetryAfterOTPExpiry from './chooseResendOrRetryAfterOTPExpiry';
import sendOtpForAddNumberOrAddBackupEmailFlow from './sendOtpForAddNumberOrAddBackupEmailFlow';
import handleBackButtonClick from './handleBackButtonClick';
import { getCallbackOfTypeSafely, getCallbackWithMessageSafely, getCallbackWithPromptSafely } from '../authTreeUtils';

const addBackupEmailbackButton = 'ciam.addBackupEmailbackButton-otp';
const addNumberbackButton = 'ciam.addNumberbackButton-otp';

const decideViewForAddNumberOrBackupEmailResult = async (dispatch, stage, ciamHistory, type) => {
  const userContextScript = stage.getCallbackOfType(CallbackType.TextOutputCallback).getOutputByName('message');
  let saveResult = false;
  let targetRoute = '';
  if (type === 'backupEmail') {
    saveResult = userContextScript.includes('"saveBackupEmailResult": "[Status: 200 OK]"');
    targetRoute = '/addbackupemailsuccess';
  } else {
    saveResult = userContextScript.includes('"saveMobileResult": "[Status: 200 OK]"');
    targetRoute = '/addmobilesuccess';
  }
  if (!saveResult) {
    updateErrorInState(dispatch, 'G_202', updateErrorCode);
  }
  await removeSessionStorageAttributes();
  ciamHistory.push(targetRoute);
};

const handleFinalResponse = async (attributes) => {
  const { dispatch, finalStep, history, otpCount, isOTPGeneratedMultipleTimes, pathBuilder, ciamHistory, type } =
    attributes;
  if (otpCount > 2 && isOTPGeneratedMultipleTimes) {
    //  No more otp attempts
    await removeSessionStorageAttributes();
    updateErrorInState(dispatch, 'D_615', updateErrorCode);
    history.push(pathBuilder('/signin', true, { searchParams: { error_code: 'D_615' } }));
  } else if (getCallbackWithMessageSafely(finalStep, MESSAGE_OTP_EXPIRED_ADD_NUMBER)) {
    // 3 attempts for 1st OTP
    updateErrorInState(dispatch, 'D_610', updateErrorCode);
  } else if (otpCount > 2 && getCallbackWithPromptSafely(finalStep, PROMPT_OTP_CHOICES)) {
    // 3 attempts for 1st OTP
    updateErrorInState(dispatch, 'D_614', updateErrorCode);
  } else if (getCallbackWithPromptSafely(finalStep, PROMPT_OTP_CHOICES)) {
    //  wrong otp entered
    updateErrorInState(dispatch, 'E_116', updateErrorCode);
  } else {
    decideViewForAddNumberOrBackupEmailResult(dispatch, finalStep, ciamHistory, type);
  }
};

export const handleOtpForAddNumberOrBackupEmailAsyncAction = async (dispatch, payload, type) => {
  dispatch(updateErrorCode(null));
  const { otp, otpCount, history, realm, brand, pathBuilder, ciamHistory } = payload;
  let { stage } = payload;
  let backButton = addNumberbackButton;
  if (type === 'backupEmail') {
    backButton = addBackupEmailbackButton;
  }
  if (getCallbackWithMessageSafely(stage, MESSAGE_OTP_RETRY_LIMIT)) {
    await removeSessionStorageAttributes();
    updateErrorInState(dispatch, 'D_622', updateErrorCode);
    history.push(pathBuilder('/signin', true, { searchParams: { error_code: 'D_615' } }));
  }
  if (otpCount === 0) {
    stage = await handleBackButtonClick(stage, realm, brand, backButton);
  }
  const isOTPGeneratedMultipleTimes = sessionStorage.getItem('isOTPGeneratedMultipleTimes');
  let resultStage = stage;
  const count = otpCount + 1;
  dispatch(updateOtpCountAction(parseInt(count, 10)));
  dispatch(updateOTPRequestedState(true));
  const choiceCallback = getCallbackOfTypeSafely(stage, CallbackType.ChoiceCallback);
  const expiredOtpCallback = getCallbackWithMessageSafely(stage, MESSAGE_OTP_EXPIRED_ADD_NUMBER);
  if (choiceCallback) {
    choiceCallback.setChoiceIndex('1');
    const step = await nextStep(dispatch, stage);
    resultStage = await sendOtpForAddNumberOrAddBackupEmailFlow(dispatch, step, otp);
    // handle any uncaught error - request not sent from UI
    checkStep(resultStage, 'D_705');
  } else if (expiredOtpCallback) {
    const secondStep = await chooseResendOrRetryAfterOTPExpiry(dispatch, stage);
    const secondStepChoiceCallback = secondStep.getCallbackOfType(CallbackType.ChoiceCallback);
    secondStepChoiceCallback.setChoiceIndex('1');
    const thirdStep = await nextStep(dispatch, secondStep);
    resultStage = await sendOtpForAddNumberOrAddBackupEmailFlow(dispatch, thirdStep, otp);
  } else {
    resultStage = await sendOtpForAddNumberOrAddBackupEmailFlow(dispatch, stage, otp);
    // handle any uncaught error - request not sent from UI
    checkStep(resultStage, 'D_705');
  }
  //  check here for result of adding number.
  handleFinalResponse({
    dispatch,
    finalStep: resultStage,
    history,
    otpCount: count,
    isOTPGeneratedMultipleTimes,
    pathBuilder,
    ciamHistory,
    type,
  });
};

export default handleOtpForAddNumberOrBackupEmailAsyncAction;
