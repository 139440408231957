import * as React from 'react';
import { useIntl } from 'react-intl';
import { MessageCard, MESSAGE_VARIANT } from '@manulife/mux';
import { FormattedRichMessage, ContentPage } from 'ciam-self-service-shared';
import Page from 'components/common/Page';

function ErrorPage() {
  const intl = useIntl();

  return (
    <Page title={intl.formatMessage({ id: 'signupVerify.error.title' })}>
      <ContentPage>
        <MessageCard variant={MESSAGE_VARIANT.PRIMARY_ALERT}>
          <FormattedRichMessage id="signupVerify.error.messageCard" />
        </MessageCard>
      </ContentPage>
    </Page>
  );
}

export default ErrorPage;
