import { updateErrorCode } from 'components/Login/actions';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useCIAMHistory } from 'ciam-self-service-shared';
import { mapErrorFromJson } from 'utils/error-map';
import AuthTreeError from 'utils/authTree/AuthTreeError';

const useAuthTreeErrorHandler = () => {
  const dispatch = useDispatch();
  const history = useCIAMHistory();
  const errorHandler = useCallback(
    (error) => {
      if (error instanceof AuthTreeError && error.code) {
        dispatch(updateErrorCode(mapErrorFromJson(error.code)));
        if (error.code === 'D_705') {
          history.push('/addbackupemail/error');
        }
      }
    },
    [dispatch, history],
  );
  return errorHandler;
};

export default useAuthTreeErrorHandler;
