import React from 'react';
import { ErrorAlert, FormattedRichMessage, ContentPage, useRichIntl } from 'ciam-self-service-shared';
import Page, { AUTH_TYPE } from 'components/common/Page';
import { useSelector } from 'react-redux';
import AnalyticsDataLayer from '../../common/Analytics';
import { ADD_BACKUP_EMAIL } from '../constants';
import AddBackupEmailPromptForm from './AddBackupEmailPromptForm';
import addBackupEmailAsyncAction from 'utils/loginAuthnTree/addBackupEmailAsyncActionImpl';
import skipBackupEmailAsyncAction from 'utils/loginAuthnTree/skipBackupEmailAsyncAction';

const AddBackupEmailPrompt = () => {
  const intl = useRichIntl();
  const userContext = useSelector((state) => state.loginInfo.userContext);
  const firstName = userContext.givenName;
  const error = useSelector((state) => state.loginInfo.loginError);
  const stage = useSelector((state) => state.loginInfo.stage);
  const addBackupEmailLoadingStatus = useSelector((state) => state.loginInfo.addBackupEmailLoadingStatus);
  const doItLaterLoadingStatus = useSelector((state) => state.loginInfo.doItLaterLoadingStatus);

  return (
    <Page
      title={intl.formatMessage({ id: 'signin.addbackupemail.title' })}
      authType={AUTH_TYPE.UNAUTHENTICATED}
      heading={intl.formatMessage(
        { id: 'signin.addbackupemail.heading' },
        {
          firstName,
        },
      )}
    >
      <AnalyticsDataLayer pageName={ADD_BACKUP_EMAIL} />
      <ContentPage heading={<FormattedRichMessage id="signin.addbackupemail.heading" values={{ firstName }} />}>
        <ErrorAlert error={error} />

        <AddBackupEmailPromptForm
          addBackupEmailHandler={addBackupEmailAsyncAction}
          skipBackupEmailHandler={skipBackupEmailAsyncAction}
          stage={stage}
          addBackupEmailLoadingStatus={addBackupEmailLoadingStatus}
          doItLaterLoadingStatus={doItLaterLoadingStatus}
        />
      </ContentPage>
    </Page>
  );
};

export default AddBackupEmailPrompt;
