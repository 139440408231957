export const INVALID_OTP = 'NONE';
export const RESEND_OTP = 0;
export const RETRY_OTP = 1;
export const SAME_CHANNEL_SELECTION = '0';
export const DIFFERENT_CHANNEL_SELECTION = '1';
export const DO_IT_LATER_SELECTION = '2';
export const ADD_NUMBER = '0';
export const ADD_NUMBER_OR_BACKUP_EMAIL = '0';
export const GO_BACK_SELECTION = '3';
export const OTP_RETRY_OR_RESEND = '0';
