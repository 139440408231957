import { CallbackType } from '@forgerock/javascript-sdk/lib';
import removeSessionStorageAttributes from 'utils/removeSessionStorageAttributes';
import { updateErrorInState } from 'ciam-self-service-shared';
import { updateOTPRequestedMultipleTimes, updateErrorCode } from '../../components/Login/actions';
import {
  MESSAGE_OTP_EXPIRED_ADD_NUMBER,
  MESSAGE_MOBILE_NUMBER,
  MESSAGE_OTP_RETRY_LIMIT,
  MESSAGE_BACKUP_EMAIL,
} from '../../components/Login/constants';
import { INVALID_OTP, GO_BACK_SELECTION } from './constants';
import chooseResendOrRetryAfterOTPExpiry from './chooseResendOrRetryAfterOTPExpiry';
import nextStep from './handleStep';
import { checkStep } from 'utils/authTree/helpers';
import handleBackButtonClick from './handleBackButtonClick';
import { getCallbackOfTypeSafely, getCallbackWithMessageSafely, getCallbackWithPromptSafely } from '../authTreeUtils';

const goBackHandlerAsyncAction = async (dispatch, payload) => {
  dispatch(updateErrorCode(null));
  dispatch(updateOTPRequestedMultipleTimes(true));
  sessionStorage.setItem('isOTPGeneratedMultipleTimes', true);
  const { history, realm, brand, ciamHistory, pathBuilder } = payload;
  let { stage } = payload;

  // after clicking back button from email account
  stage = await handleBackButtonClick(stage, realm, brand, 'ciam.addNumberbackButton-otp');
  let step = stage;
  let resultStage = '';
  if (getCallbackWithMessageSafely(stage, MESSAGE_OTP_RETRY_LIMIT)) {
    await removeSessionStorageAttributes();
    updateErrorInState(dispatch, 'D_622', updateErrorCode);
    history.push(pathBuilder('/signin', true, { searchParams: { error_code: 'D_622' } }));
  }

  const passwordCallback = getCallbackOfTypeSafely(stage, CallbackType.PasswordCallback);
  if (passwordCallback) {
    passwordCallback.setPassword(INVALID_OTP);
    step = await nextStep(dispatch, stage);
    // handle any uncaught error - request not sent from UI
    checkStep(step, 'D_705');
  }

  const choiceCallback = getCallbackOfTypeSafely(step, CallbackType.ChoiceCallback);
  if (choiceCallback) {
    choiceCallback.setChoiceIndex(GO_BACK_SELECTION);
    resultStage = await nextStep(dispatch, step);
  }

  const otpExpiredCallback = getCallbackWithMessageSafely(stage, MESSAGE_OTP_EXPIRED_ADD_NUMBER);
  if (otpExpiredCallback) {
    const secondStep = await chooseResendOrRetryAfterOTPExpiry(dispatch, stage);
    const secondStepChoiceCallback = secondStep.getCallbackOfType(CallbackType.ChoiceCallback);
    secondStepChoiceCallback.setChoiceIndex(GO_BACK_SELECTION);
    resultStage = await nextStep(dispatch, secondStep);
  }

  if (getCallbackWithMessageSafely(resultStage, MESSAGE_OTP_RETRY_LIMIT)) {
    await removeSessionStorageAttributes();
    updateErrorInState(dispatch, 'D_622', updateErrorCode);
    history.push(pathBuilder('/signin', true, { searchParams: { error_code: 'D_622' } }));
  }
  if (getCallbackWithPromptSafely(resultStage, MESSAGE_MOBILE_NUMBER)) {
    ciamHistory.push('/addnumber');
  }
  if (getCallbackWithPromptSafely(resultStage, MESSAGE_BACKUP_EMAIL)) {
    ciamHistory.push('/addbackupemail');
  }
};

export default goBackHandlerAsyncAction;
