import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { BUTTON_VARIANT } from '@manulife/mux';
import {
  useCIAMHistory,
  useRealm,
  useCIAMContextParam,
  ContentPage,
  FormattedRichMessage,
  CoBrandingButton,
  customStyles,
  useRichIntl,
} from 'ciam-self-service-shared';
import { useDispatch, useSelector } from 'react-redux';
import Page, { AUTH_TYPE } from '../../common/Page';
import FormElement from '../../common/FormElement';
import AnalyticsDataLayer from '../../common/Analytics';
import { BACKUP_EMAIL_CONFIRMED } from '../constants';
import useGoToUrl from '../../../hooks/useGoToUrl';
import gotoMyAccountHandlerAsyncActionImpl from 'utils/loginAuthnTree/gotoMyAccountHandlerAsyncActionImpl';

const AddBackupEmailFinish = () => {
  const intl = useRichIntl();
  const userContext = useSelector((state) => state.loginInfo.userContext);
  const firstName = userContext.givenName;
  const dispatch = useDispatch();
  const history = useCIAMHistory();
  const stage = useSelector((state) => state.loginInfo.stage);
  const gotoURI = useGoToUrl();
  const { locale } = useIntl();
  const realm = useRealm();
  const brand = useCIAMContextParam('brand');

  const connectProducts = () => {
    const payload = {
      stage,
      history,
      gotoURI,
      locale,
      realm,
      brand,
    };
    gotoMyAccountHandlerAsyncActionImpl(dispatch, payload);
  };

  return (
    <Page
      title={intl.formatMessage({ id: 'signin.addbackupemail.title' })}
      authType={AUTH_TYPE.UNAUTHENTICATED}
      heading={intl.formatMessage({ id: 'signin.addbackupemail.verify.finish.heading' })}
    >
      <AnalyticsDataLayer pageName={BACKUP_EMAIL_CONFIRMED} />
      <ContentPage
        heading={<FormattedRichMessage id="signin.addbackupemail.verify.finish.heading" values={{ firstName }} />}
      >
        <FormattedRichMessage id="signin.addbackupemail.verify.finish.text" />
        <FormElement spacing={2}>
          <CoBrandingButton
            variant={BUTTON_VARIANT.PRIMARY}
            customStyle={customStyles.button}
            onClick={connectProducts}
            id="connect-products-btn"
          >
            <FormattedMessage id="signin.addbackupemail.verify.finish.connectButton" />
          </CoBrandingButton>
        </FormElement>
      </ContentPage>
    </Page>
  );
};

export default AddBackupEmailFinish;
