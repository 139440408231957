import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Tooltip, BUTTON_VARIANT, P } from '@manulife/mux';
import styled from 'styled-components';
import {
  Form,
  FormTextInput,
  FormattedRichMessage,
  validEmail,
  CoBrandingButton,
  StageShape,
  customStyles,
  Link,
  useCIAMHistory,
  useRealm,
  useCIAMContextParam,
} from 'ciam-self-service-shared';
import FormElement from 'components/common/FormElement';
import { useDispatch } from 'react-redux';
import useGoToUrl from '../../../hooks/useGoToUrl';
import { updateBackupEmailLoadingStatus, updateDoItLaterLoadingStatus } from 'components/Login/actions';
import useAuthTreeErrorHandler from '../authTree/useAuthTreeErrorHandler';

export const Wrapper = styled.div`
  .custom-link-button {
    padding-left: 0px;
  }
`;

const AddBackupEmailPromptForm = (props) => {
  const [backupEmail, setBackupEmail] = useState('');
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useCIAMHistory();
  const gotoURI = useGoToUrl();
  const realm = useRealm();
  const brand = useCIAMContextParam('brand');
  const { locale } = useIntl();
  const authTreeErrorHandler = useAuthTreeErrorHandler();
  const { addBackupEmailHandler, skipBackupEmailHandler, stage, addBackupEmailLoadingStatus, doItLaterLoadingStatus } =
    props;

  const handleAddBackupEmail = async () => {
    const payload = {
      backupEmail,
      stage,
      history,
      brand,
    };

    try {
      dispatch(updateBackupEmailLoadingStatus(true));
      await addBackupEmailHandler(dispatch, payload);
    } catch (err) {
      authTreeErrorHandler(err);
    } finally {
      dispatch(updateBackupEmailLoadingStatus(false));
    }
  };

  const handleCancelAddBackupEmail = async () => {
    const payload = {
      stage,
      history,
      gotoURI,
      locale,
      realm,
      brand,
    };
    try {
      dispatch(updateDoItLaterLoadingStatus(true));
      await skipBackupEmailHandler(dispatch, payload);
    } catch (err) {
      authTreeErrorHandler(err);
    } finally {
      dispatch(updateDoItLaterLoadingStatus(false));
    }
  };

  return (
    <Form fullWidth={false} onSubmit={handleAddBackupEmail}>
      <P>
        <FormattedRichMessage id="signin.addbackupemail.info" />
        <Tooltip
          content={<FormattedRichMessage id="signin.addbackupemail.info.toolTip" />}
          variant={Tooltip.VARIANT.SECONDARY}
        />
      </P>

      <FormElement>
        <FormTextInput
          id="backupEmail"
          inputMode="email"
          autoComplete="email"
          label={intl.formatMessage({ id: 'signin.addbackupemail.backupemail' })}
          value={backupEmail}
          onChange={setBackupEmail}
          required
          ariaRequired
          validations={validEmail}
          validationsCombinedLabel={intl.formatMessage({ id: 'common.email.validation.combined' })}
        />
      </FormElement>

      <FormElement spacing={2}>
        <CoBrandingButton
          id="send-one-time-code"
          type="submit"
          variant={BUTTON_VARIANT.PRIMARY}
          customStyle={customStyles.button}
          disabled={addBackupEmailLoadingStatus}
        >
          <FormattedMessage id="signin.addbackupemail.submitButton" />
        </CoBrandingButton>
      </FormElement>

      <FormElement spacing={1}>
        <Wrapper>
          <CoBrandingButton
            id="skip-add-backupEmail"
            as={Link}
            className="custom-link-button"
            variant={BUTTON_VARIANT.TERTIARY}
            onClick={handleCancelAddBackupEmail}
            disabled={doItLaterLoadingStatus}
          >
            <FormattedMessage id="signin.addbackupemail.skipbutton" />
          </CoBrandingButton>
        </Wrapper>
      </FormElement>
    </Form>
  );
};

AddBackupEmailPromptForm.propTypes = {
  addBackupEmailHandler: PropTypes.func.isRequired,
  skipBackupEmailHandler: PropTypes.func.isRequired,
  stage: StageShape.isRequired,
  addBackupEmailLoadingStatus: PropTypes.bool,
  doItLaterLoadingStatus: PropTypes.bool,
};

export default AddBackupEmailPromptForm;
