import dayjs from 'dayjs';
import initState from '../../reducers/initState';
import ActionTypes from './actionTypes';

// eslint-disable-next-line import/prefer-default-export
export const loginInfo = (state = initState.loginInfo, action = {}) => {
  switch (action.type) {
    case ActionTypes.INIT_STORE: {
      return action.loginInfo;
    }
    case ActionTypes.UPDATE_REALM: {
      const { realm } = action;
      return {
        ...state,
        realm,
      };
    }
    case ActionTypes.UPDATE_USERNAME: {
      const { username } = action;
      return {
        ...state,
        UsernameValue: username,
      };
    }
    case ActionTypes.UPDATE_LOGIN_LOADING_STATUS: {
      const { loginLoadingStatus } = action;
      return {
        ...state,
        loginLoadingStatus,
      };
    }
    case ActionTypes.LOG_IN_SUCCESS: {
      const { realm, tokenId } = action;
      return {
        ...state,
        realm,
        tokenId,
        isLoggedIn: true,
        isLoggedOut: false,
      };
    }

    case ActionTypes.LOGIN_AUTHN_TREE_STEP: {
      const { stage } = action;
      return {
        ...state,
        stage,
      };
    }

    case ActionTypes.LOGIN_OTP_MULTIPLE_TIMES: {
      const { isOTPGeneratedMultipleTimes } = action;
      return {
        ...state,
        isOTPGeneratedMultipleTimes,
      };
    }

    case ActionTypes.LOGIN_UPDATE_USER_CONTEXT: {
      const { userContext } = action;
      return {
        ...state,
        userContext,
      };
    }

    case ActionTypes.OTP_COUNT: {
      const { otpCount } = action;
      return {
        ...state,
        otpCount,
      };
    }
    case ActionTypes.LOGIN_MOBILE_NUMBER_ADDED: {
      const { isMobileNumberAdded, mobileNumber } = action;
      return {
        ...state,
        isMobileNumberAdded,
        mobileNumber,
      };
    }
    case ActionTypes.LOGIN_BACKUP_EMAIL_ADDED: {
      const { isBackupEmailAdded, backupEmail } = action;
      return {
        ...state,
        isBackupEmailAdded,
        backupEmail,
      };
    }
    case ActionTypes.UPDATE_ADD_NUMBER_STATUS: {
      const { addNumberLoadingStatus } = action;
      return {
        ...state,
        addNumberLoadingStatus,
      };
    }
    case ActionTypes.UPDATE_DO_IT_LATER_STATUS: {
      const { doItLaterLoadingStatus } = action;
      return {
        ...state,
        doItLaterLoadingStatus,
      };
    }
    case ActionTypes.UPDATE_GO_BACK_STATUS: {
      const { goBackLoadingStatus } = action;
      return {
        ...state,
        goBackLoadingStatus,
      };
    }

    case ActionTypes.OTP_REQUESTED_STATE: {
      const { isOTPRequested } = action;
      return {
        ...state,
        isOTPRequested,
      };
    }

    case ActionTypes.IS_LOGIN_AFTER_ERROR: {
      const { isLoginAfterError } = action;
      return {
        ...state,
        isLoginAfterError,
      };
    }

    case ActionTypes.CREATE_SESSION_STATUS: {
      const { isSessionCreated } = action;
      return {
        ...state,
        isSessionCreated,
      };
    }
    case ActionTypes.UPDATE_ERROR_CODE: {
      const { loginError } = action;
      return {
        ...state,
        loginError,
      };
    }
    case ActionTypes.UPDATE_IS_LOGOUT: {
      const { isLoggedOut } = action;
      return {
        ...state,
        isLoggedOut,
      };
    }
    case ActionTypes.LOGOUT_START: {
      return {
        ...state,
        logoutStarted: true,
      };
    }

    case ActionTypes.LOGOUT_SUCCESS: {
      return {
        ...state,
        isLoggedIn: false,
        loginSuccess: false,
        userContext: {},
        stage: {},
        isLoggedOut: true,
        tokenId: '',
        logoutStarted: false,
      };
    }
    case ActionTypes.LOGOUT_FAILURE: {
      const todo = action.payload;
      return {
        ...state,
        isLoggedIn: true,
        isLoggedOut: false,
        logoutError: true,
        logoutStarted: false,
        error: {
          code: 401,
          reason: 'Logout Failed',
          message: todo,
          detail: {},
        },
      };
    }
    case ActionTypes.LOGOUT_CLEANUP: {
      return {
        ...state,
        logoutError: false,
        error: {},
      };
    }
    case ActionTypes.VALIDATE_SESSION_START: {
      return {
        ...state,
        isLoggedIn: undefined,
      };
    }
    case ActionTypes.VALIDATE_SESSION_SUCCESS: {
      return {
        ...state,
        isLoggedIn: true,
      };
    }
    case ActionTypes.VALIDATE_SESSION_FAIL: {
      return {
        ...state,
        isLoggedIn: false,
      };
    }
    case ActionTypes.SESSION_INFO_SUCCESS: {
      const { sessionInfo } = action.payload;
      return {
        ...state,
        sessionInfo: {
          maxIdleExpirationTime: dayjs().add(sessionInfo?.sessionRemainingIdleTimeInSeconds, 'second'),
          maxSessionExpirationTime: dayjs(new Date(sessionInfo.maxSessionExpirationTime)),
          hashedCiamId: sessionInfo.hashedCiamId,
          mmidPasswordRequired: sessionInfo.mmidPasswordRequired,
        },
        sessionInfoFetched: true,
      };
    }
    default: {
      return state;
    }
  }
};
