import { AsyncActionState } from '../utils/constants';

const initState = {
  otp: {
    mobileNumber: '',
    primaryEmail: '',
    secondaryEmail: '',
    sendStatus: false,
  },

  forgotPasswordInfo: {
    stage: {},
    userName: '',
    error: null,
    passwordUpdateStatus: false,
  },

  forgotUsernameInfo: {
    emailOrPhoneValue: '',
  },

  loginInfo: {
    UsernameValue: '',
    passwordValue: '',
    tokenId: '',
    isLoggedIn: undefined,
    loginLoadingStatus: false,
    addNumberLoadingStatus: false,
    doItLaterLoadingStatus: false,
    goBackLoadingStatus: false,
    authenticationId: '',
    stage: {},
    isOTPGeneratedMultipleTimes: false,
    isMobileNumberAdded: false,
    mobileNumber: '',
    backupEmail: '',
    userContext: {},
    otpCount: 0,
    error: {
      code: 0,
      reason: '',
      message: '',
      detail: {
        result: false,
        failedPolicyRequirements: [],
      },
    },
    loginError: null,
    logoutError: false,
    logoutStarted: false,
    isOTPRequested: false,
    isLoginAfterError: false,
    realm: '',
    isLoggedOut: false,
    sessionInfoFetched: false,
    sessionInfo: {
      username: null,
      maxIdleExpirationTime: null,
      maxSessionExpirationTime: null,
      hashedCiamId: null,
      mmidPasswordRequired: null,
    },
  },

  intl: {
    locale: 'en-CA',
    messages: {},
  },

  registrationSuccessInfo: {
    MobileNumber: '',
    SecondaryEmail: '',
  },

  verifyInfo: {
    success: 0,
  },

  registrationInfo: {
    username: '',
    password: '',
    email: '',
    registrationState: AsyncActionState.Idle,
    error: {
      code: 0,
      reason: '',
      message: '',
      detail: {
        result: false,
        failedPolicyRequirements: [],
      },
    },
  },
  userInfo: {
    stage: {},
    criteriaList: [],
    usersList: [],
    updateUser: {
      _id: '',
      userName: '',
      accountStatus: '',
      sn: '',
      postalCode: '',
      stateProvince: '',
      displayName: '',
      description: '',
      country: '',
      givenName: '',
      address2: '',
      city: '',
      telephoneNumber: '',
      mail: '',
      dob: '',
    },
  },

  verifyPassword: {
    verified: false,
    failureCount: 0,
  },

  confirmEmail: {
    confirmEmailStatus: '',
    name: '',
  },
  mfa: {
    requestInProgress: {
      phone: false,
      email: false,
      backupemail: false,
    },
  },
};

export default initState;
