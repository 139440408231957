import { CallbackType, StepType } from '@forgerock/javascript-sdk/lib';
import { checkStep } from 'utils/authTree/helpers';
import nextStep from './handleStep';
import doItLaterHandlerAsyncAction from './doItLaterHandlerAsyncAction';
import { getCallbackOfTypeSafely } from '../authTreeUtils';

const SKIP_BACKUP_EMAIL = 'skipbackupemail';

const skipBackupEmailAsyncAction = async (dispatch, payload) => {
  const { stage, history, gotoURI, locale, realm, brand } = payload;

  const textOutputCallback = getCallbackOfTypeSafely(stage, CallbackType.TextOutputCallback);
  if (textOutputCallback) {
    const confirmationCallback = stage.getCallbackOfType(CallbackType.ConfirmationCallback);
    confirmationCallback.setInputValue('1');
    const finalStep = await nextStep(dispatch, stage);
    if (finalStep?.type === StepType.LoginSuccess) {
      history.push('/connectproducts');
    } else {
      // handle any uncaught error - request not sent from UI
      checkStep(finalStep, 'D_705');
    }
  }

  const nameCallback = getCallbackOfTypeSafely(stage, CallbackType.NameCallback);
  if (nameCallback) {
    nameCallback.setName(SKIP_BACKUP_EMAIL);
    const secondStep = await nextStep(dispatch, stage);
    if (secondStep && secondStep !== StepType.LoginFailure) {
      doItLaterHandlerAsyncAction(dispatch, { stage: secondStep, history, gotoURI, locale, realm, brand });
    } else {
      // handle any uncaught error - request not sent from UI
      checkStep(secondStep, 'D_705');
    }
  }

  if (!nameCallback && !textOutputCallback) {
    // handle any uncaught error - request not sent from UI
    checkStep(null, 'D_705');
  }
};

export default skipBackupEmailAsyncAction;
