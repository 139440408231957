import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { LANG } from '@manulife/mux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import MESSAGES from '../translations';
import rootReducer from '../reducers/rootReducer';

export const initStore = {
  intl: {
    locale: 'en-CA',
    messages: MESSAGES[LANG.EN_CA],
  },
  forgotPasswordInfo: {
    stage: {},
    userName: '',
    error: null,
    passwordUpdateStatus: false,
  },
  forgotUsernameInfo: {
    emailOrPhoneValue: '',
  },

  loginInfo: {
    UsernameValue: '',
    passwordValue: '',
    tokenId: '',
    isLoggedIn: undefined,
    loginLoadingStatus: false,
    addNumberLoadingStatus: false,
    addBackupEmailLiadingStatus: false,
    doItLaterLoadingStatus: false,
    goBackLoadingStatus: false,
    secondaryMail: 'test@abc.com',
    authenticationId: '',
    userContext: {},
    stage: {},
    error: {
      code: 0,
      reason: '',
      message: '',
      detail: {
        result: false,
        failedPolicyRequirements: [],
      },
    },
    loginError: '',
    isOTPRequested: false,
    isLoginAfterError: false,
    realm: '',
    isLoggedOut: false,
    logoutError: false,
    logoutStarted: false,
    sessionInfo: {
      username: null,
      maxIdleExpirationTime: null,
      maxSessionExpirationTime: null,
      hashedCiamId: null,
      mmidPasswordRequired: null,
    },
  },

  verifyInfo: {
    success: 0,
  },
  registrationInfo: {
    username: '',
    password: '',
    email: '',
    loading: 'unfinished',
    error: {
      code: 0,
      reason: '',
      message: '',
      detail: {
        result: false,
        failedPolicyRequirements: [],
      },
    },
  },

  otp: {
    primaryEmail: '',
    mobileNumber: '',
    secondaryEmail: '',
    sendStatus: false,
  },

  registrationSuccessInfo: {
    SecondaryEmail: '',
    MobileNumber: '',
  },
  // userInfo: {
  //   stage: '',
  //   criteriaList: [],
  //   usersList: [],
  //   updateUser: {
  //     _id: '',
  //     userName: '',
  //     accountStatus: '',
  //     sn: '',
  //     postalCode: '',
  //     stateProvince: '',
  //     displayName: '',
  //     description: '',
  //     country: '',
  //     givenName: '',
  //     address2: '',
  //     city: '',
  //     telephoneNumber: '',
  //     mail: '',
  //     dob: '',
  //   },
  // },
  verifyPassword: {
    verified: false,
    failureCount: 0,
  },

  confirmEmail: {
    confirmEmailStatus: '',
    name: '',
  },
  mfa: {
    requestInProgress: {
      phone: false,
      email: false,
      backupemail: false,
    },
  },
};

export const configureStore = () => {
  const middleware = window.REACT_ENV === 'prod' ? applyMiddleware(thunk) : composeWithDevTools(applyMiddleware(thunk));

  return createStore(rootReducer, initStore, middleware);
};
