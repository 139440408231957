import { any } from 'prop-types';
import client from '../../restClients/authenticatedBff';
import { asyncWrapperForSessionManage } from '../../utils/async';
import ActionTypes from './actionTypes';

export const INIT_SESSION_ENDPOINT = `init-session`;

export const initStoreAction = (intl, loginInfo) => {
  return {
    type: any,
    loginInfo,
    intl,
  };
};
export const updateRealmAction = (realm) => {
  return {
    type: ActionTypes.UPDATE_REALM,
    realm,
  };
};
export const updateUsernameAction = (username) => {
  return {
    type: ActionTypes.UPDATE_USERNAME,
    username,
  };
};
export const loginFailureAction = (payload) => {
  return {
    type: ActionTypes.LOGIN_FAILURE,
    payload,
  };
};
export const LogInSuccess = (payload) => {
  return {
    type: ActionTypes.LOG_IN_SUCCESS,
    realm: payload.realm,
    tokenId: payload.tokenId,
  };
};
export const updateLoginLoadingStatus = (loginLoadingStatus) => {
  return {
    type: ActionTypes.UPDATE_LOGIN_LOADING_STATUS,
    loginLoadingStatus,
  };
};
export const updateAddNumberLoadingStatus = (addNumberLoadingStatus) => {
  return {
    type: ActionTypes.UPDATE_ADD_NUMBER_STATUS,
    addNumberLoadingStatus,
  };
};
export const updateBackupEmailLoadingStatus = (addBackupEmailLoadingStatus) => {
  return {
    type: ActionTypes.UPDATE_ADD_NUMBER_STATUS,
    addBackupEmailLoadingStatus,
  };
};
export const updateDoItLaterLoadingStatus = (doItLaterLoadingStatus) => {
  return {
    type: ActionTypes.UPDATE_DO_IT_LATER_STATUS,
    doItLaterLoadingStatus,
  };
};
export const updateGoBackLoadingStatus = (goBackLoadingStatus) => {
  return {
    type: ActionTypes.UPDATE_GO_BACK_STATUS,
    goBackLoadingStatus,
  };
};
export const actionCreators = {
  LogInSuccess,
};
export const updateStageAction = (stage) => {
  return {
    type: ActionTypes.LOGIN_AUTHN_TREE_STEP,
    stage,
  };
};
export const updateOtpCountAction = (otpCount) => {
  return {
    type: ActionTypes.OTP_COUNT,
    otpCount,
  };
};
export const updateUserContext = (userContext) => {
  return {
    type: ActionTypes.LOGIN_UPDATE_USER_CONTEXT,
    userContext,
  };
};
export const updateErrorCode = (loginError) => {
  return {
    type: ActionTypes.UPDATE_ERROR_CODE,
    loginError,
  };
};
export const updateIsLoggedOut = (isLoggedOut) => {
  return {
    type: ActionTypes.UPDATE_IS_LOGOUT,
    isLoggedOut,
  };
};
export const updateOTPRequestedMultipleTimes = (isOTPGeneratedMultipleTimes) => {
  return {
    type: ActionTypes.LOGIN_OTP_MULTIPLE_TIMES,
    isOTPGeneratedMultipleTimes,
  };
};
export const updateMobileNumberAdded = (isMobileNumberAdded, mobileNumber) => {
  return {
    type: ActionTypes.LOGIN_MOBILE_NUMBER_ADDED,
    isMobileNumberAdded,
    mobileNumber,
  };
};
export const updateBackupEmailAdded = (isBackupEmailAdded, backupEmail) => {
  return {
    type: ActionTypes.LOGIN_BACKUP_EMAIL_ADDED,
    isBackupEmailAdded,
    backupEmail,
  };
};
export const updateOTPRequestedState = (isOTPRequested) => {
  return {
    type: ActionTypes.OTP_REQUESTED_STATE,
    isOTPRequested,
  };
};
export const isLoginAfterError = (errorState) => {
  return {
    type: ActionTypes.IS_LOGIN_AFTER_ERROR,
    isLoginAfterError: errorState,
  };
};
export const createSessionStatus = ({ isSessionCreated }) => {
  return {
    type: ActionTypes.CREATE_SESSION_STATUS,
    isSessionCreated,
  };
};
export function getSessionInfoSuccess(sessionInfo) {
  return {
    type: ActionTypes.SESSION_INFO_SUCCESS,
    payload: {
      sessionInfo,
    },
  };
}
export function getSessionInfoFailure() {
  return {
    type: ActionTypes.SESSION_INFO_FAILURE,
    payload: {},
  };
}

const getBody = (data) => {
  if (data !== null) {
    return {
      tokenId: data.payload.tokenId,
      realm: data.payload.realm,
    };
  }
  return {
    tokenId: '',
    realm: '',
  };
};

export const createSessionActionImpl = async (data = null) => {
  const body = getBody(data);

  const response = await client.post(INIT_SESSION_ENDPOINT, body);

  return {
    isSessionCreated: response.ok,
  };
};

export const createSessionAction = asyncWrapperForSessionManage(createSessionActionImpl);

export const sessionValidating = () => ({
  type: ActionTypes.VALIDATE_SESSION_START,
});

export const sessionValidated = () => {
  return {
    type: ActionTypes.VALIDATE_SESSION_SUCCESS,
  };
};

export const sessionValidateFail = () => {
  return {
    type: ActionTypes.VALIDATE_SESSION_FAIL,
  };
};
