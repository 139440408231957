import { CallbackType } from '@forgerock/javascript-sdk/lib';
import {
  updateOTPRequestedState,
  updateOtpCountAction,
  updateErrorCode,
  updateBackupEmailAdded,
} from 'components/Login/actions';
import { checkStep } from 'utils/authTree/helpers';
import { getCallbackOfTypeSafely, getCallbackWithMessageSafely } from '../authTreeUtils';
import { ADD_NUMBER_OR_BACKUP_EMAIL } from './constants';
import executeHiddenCallbackForOTPAutomatedTesting from './executeHiddenCallbackForOTPAutomatedTesting';
import nextStep from './handleStep';
import { MESSAGE_BACKUP_EMAIL_INVALID } from 'components/Login/constants';
import { mapErrorFromJson } from 'utils/error-map';

export const addBackupEmailAsyncAction = async (dispatch, payload) => {
  dispatch(updateErrorCode(null));
  sessionStorage.removeItem('ciam.backButton-otp');
  sessionStorage.removeItem('ciam.addBackupEmailbackButton-otp');
  const { stage, backupEmail, history } = payload;
  dispatch(updateOtpCountAction(0));
  dispatch(updateBackupEmailAdded(true, backupEmail));
  dispatch(updateOTPRequestedState(false));
  let resultStep = '';

  const nameCallback = getCallbackOfTypeSafely(stage, CallbackType.NameCallback);
  if (nameCallback) {
    nameCallback.setName(backupEmail);
    const secondStep = await nextStep(dispatch, stage);
    if (getCallbackOfTypeSafely(secondStep, CallbackType.PasswordCallback)) {
      dispatch(updateOTPRequestedState(true));
    }
    resultStep = await executeHiddenCallbackForOTPAutomatedTesting(dispatch, secondStep);
  } else if (getCallbackOfTypeSafely(stage, CallbackType.TextOutputCallback)) {
    const confirmationCallback = stage.getCallbackOfType(CallbackType.ConfirmationCallback);
    confirmationCallback.setInputValue(ADD_NUMBER_OR_BACKUP_EMAIL);
    const step = await nextStep(dispatch, stage);

    const nextStepNameCallback = getCallbackOfTypeSafely(step, CallbackType.NameCallback);
    if (nextStepNameCallback) {
      nextStepNameCallback.setName(backupEmail);
      const secondStep = await nextStep(dispatch, step);
      resultStep = await executeHiddenCallbackForOTPAutomatedTesting(dispatch, secondStep);
    } else {
      // handle any uncaught error - request not sent from UI
      checkStep(step, 'D_705');
    }
  } else {
    // nothing to do
  }
  if (getCallbackOfTypeSafely(resultStep, CallbackType.PasswordCallback)) {
    // store stage-info in local storage to fetch again for back button purposes
    sessionStorage.setItem('ciam.addBackupEmailbackButton-otp', JSON.stringify(resultStep));
    dispatch(updateOTPRequestedState(true));
    history.push('/addbackupemailmfa');
  } else if (getCallbackWithMessageSafely(resultStep, MESSAGE_BACKUP_EMAIL_INVALID)) {
    dispatch(updateErrorCode(mapErrorFromJson('D_637')));
  } else {
    // handle any uncaught error - request not sent from UI
    checkStep(null, 'D_705');
  }
};

export default addBackupEmailAsyncAction;
