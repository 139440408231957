import { CallbackType, Config, StepType } from '@forgerock/javascript-sdk/lib';
import {
  updateOTPRequestedState,
  updateUsernameAction,
  updateErrorCode,
  updateIsLoggedOut,
  updateLoginLoadingStatus,
} from 'components/Login/actions';
import {
  MESSAGE_LOCKED_ACCOUNT,
  MESSAGE_MFA_CHANNEL,
  MESSAGE_ADD_VERIFY_MOBILE,
  MESSAGE_ADD_VERIFY_BACKUP_EMAIL,
  AUTH_TREE_REALM_MAPPING,
  INVALID_LOGIN_SESSION_NAME,
} from 'components/Login/constants';
import removeSessionStorageAttributes from 'utils/removeSessionStorageAttributes';
import { updateErrorInState } from 'ciam-self-service-shared';
import { mapErrorFromJson } from 'utils/error-map';
import { checkStep } from 'utils/authTree/helpers';
import determineConfig from '../frAuthConfig';
import executeHiddenCallbackForDevicePrint from './handleDeviceFingerprint';
import nextStep from './handleStep';
import successRedirection from './common/successRedirection';
import { getCallbackWithMessageSafely, getCallbackWithPromptSafely } from '../authTreeUtils';

const ERROR_401 = 401;
const ERROR_500 = 500;
const DEFAULT_LIMIT = 3;
const COUNT_LIMIT = window.ERROR_401_COUNTER_LIMIT || DEFAULT_LIMIT;

export const getInvalidLoginCountFromSession = (realm) => {
  let invalidLoginCount = sessionStorage.getItem(`${INVALID_LOGIN_SESSION_NAME}-${realm}`);
  if (!invalidLoginCount) {
    invalidLoginCount = 0;
    sessionStorage.setItem(`${INVALID_LOGIN_SESSION_NAME}-${realm}`, JSON.stringify(invalidLoginCount));
  }
  return JSON.parse(invalidLoginCount);
};

const setInvalidLoginCountInSession = (realm, count) => {
  sessionStorage.setItem(`${INVALID_LOGIN_SESSION_NAME}-${realm}`, JSON.stringify(count));
};

const decideViewAfterSignInFlow = async (dispatch, finalStep, history, gotoURI, locale, realm, brand) => {
  if (finalStep.type === StepType.LoginSuccess) {
    await successRedirection(dispatch, { history, finalStep, gotoURI, locale, realm, brand });
  } else if (getCallbackWithPromptSafely(finalStep, MESSAGE_MFA_CHANNEL)) {
    history.push('/mfa');
  } else if (getCallbackWithMessageSafely(finalStep, MESSAGE_ADD_VERIFY_MOBILE)) {
    history.push('/addnumber');
  } else if (getCallbackWithMessageSafely(finalStep, MESSAGE_ADD_VERIFY_BACKUP_EMAIL)) {
    history.push('/addbackupemail');
  } else {
    // nothing to do
  }
};

const handleFinalResponse = async (dispatch, payload) => {
  const { thirdStep, username, history, gotoURI, locale, realm, brand } = payload;
  let errorCode;
  let invalidLoginCounter = 0;
  const invalidLoginCount = getInvalidLoginCountFromSession(realm);
  if (thirdStep?.payload?.code === ERROR_401) {
    errorCode = 'D_107';
    invalidLoginCounter = invalidLoginCount + 1; // increment the 401 count for the current realm
    if (invalidLoginCounter >= COUNT_LIMIT) {
      errorCode = 'D_108'; // error code for 401 error hitting the limit or more
    }
  } else if (thirdStep?.payload?.code === ERROR_500) {
    errorCode = 'D_505';
  } else if (getCallbackWithMessageSafely(thirdStep, MESSAGE_LOCKED_ACCOUNT)) {
    errorCode = 'D_603';
  } else {
    checkStep(thirdStep, 'D_705');
  }
  // if 401 error,update the realm 401 count
  // for all other errors/success, the count will be updated as zero
  setInvalidLoginCountInSession(realm, invalidLoginCounter);
  if (errorCode) {
    if (errorCode === 'D_108') {
      dispatch(updateErrorCode(mapErrorFromJson('D_108'))); // get the error details from errors.json
    } else {
      updateErrorInState(dispatch, errorCode, updateErrorCode);
    }
  } else {
    const userInfoRes = thirdStep.getCallbackOfType(CallbackType.TextOutputCallback).getOutputByName('message');
    if (userInfoRes && userInfoRes.includes('ciamUniqueID')) {
      const userInfo = JSON.parse(userInfoRes);
      sessionStorage.setItem(`userName-${realm}`, username);
      sessionStorage.setItem(`ciamId-${realm}`, userInfo.ciamUniqueID);
      history.push('/activation');
    } else {
      const finalStep = await executeHiddenCallbackForDevicePrint(dispatch, thirdStep);
      await decideViewAfterSignInFlow(dispatch, finalStep, history, gotoURI, locale, realm, brand);
    }
  }
};

const signinAsyncAction = async (dispatch, payload) => {
  await removeSessionStorageAttributes();
  dispatch(updateErrorCode(null));
  dispatch(updateIsLoggedOut(false));
  dispatch(updateLoginLoadingStatus(true));
  const { username, password, history, gotoURI, locale, realm, brand } = payload;
  dispatch(updateUsernameAction(username));
  Config.set(determineConfig(AUTH_TREE_REALM_MAPPING[realm], realm, brand));
  const secondStep = await nextStep(dispatch);
  if (secondStep.type === StepType.LoginSuccess) {
    await successRedirection(dispatch, { history, finalStep: secondStep, gotoURI, locale, realm, brand });
    setInvalidLoginCountInSession(realm, 0);
    dispatch(updateLoginLoadingStatus(false));
  } else {
    dispatch(updateOTPRequestedState(false));
    const nameCallback = secondStep.getCallbackOfType(CallbackType.NameCallback);
    const passwordCallback = secondStep.getCallbackOfType(CallbackType.PasswordCallback);
    nameCallback.setName(username);
    passwordCallback.setPassword(password);
    const thirdStep = await nextStep(dispatch, secondStep);
    // handle final response here to decide between error handling or re-routing to next-step in tree
    await handleFinalResponse(dispatch, {
      thirdStep,
      username,
      history,
      gotoURI,
      locale,
      realm,
      brand,
    });
    dispatch(updateLoginLoadingStatus(false));
  }
};

export default signinAsyncAction;
