import { CallbackType, StepType } from '@forgerock/javascript-sdk/lib';
import { changePasswordUpdateStatus } from 'components/ForgotPassword/actions';
import { updateOTPRequestedState, updateOtpCountAction, updateErrorCode } from 'components/Login/actions';
import {
  MESSAGE_OTP_EXPIRED,
  MESSAGE_ADD_VERIFY_MOBILE,
  MESSAGE_ADD_VERIFY_BACKUP_EMAIL,
  MESSAGE_RESEND_OR_RETRY,
  MESSAGE_TRY_NEW_OTP,
  MESSAGE_OTP_RETRY_LIMIT,
  MESSAGE_ENTER_PASSWORD,
} from 'components/Login/constants';
import removeSessionStorageAttributes from 'utils/removeSessionStorageAttributes';
import { updateErrorInState } from 'ciam-self-service-shared';
import { checkStep } from 'utils/authTree/helpers';
import chooseResendOrRetryAfterOTPExpiry from './chooseResendOrRetryAfterOTPExpiry';
import sendOtp from './sendOtp';
import nextStep from './handleStep';
import { RETRY_OTP } from './constants';
import successRedirection from './common/successRedirection';
import handleBackButtonClick from './handleBackButtonClick';
import { getCallbackOfTypeSafely, getCallbackWithMessageSafely, getCallbackWithPromptSafely } from '../authTreeUtils';

const ciamBackButton = 'ciam.backButton-otp';

const decideViewAfterOTPadd = async (dispatch, finalStep, ciamHistory) => {
  if (getCallbackWithMessageSafely(finalStep, MESSAGE_ADD_VERIFY_MOBILE)) {
    sessionStorage.removeItem(ciamBackButton);
    sessionStorage.removeItem('isOTPGeneratedMultipleTimes');
    ciamHistory.push('/addnumber');
  } else if (getCallbackWithPromptSafely(finalStep, MESSAGE_ADD_VERIFY_BACKUP_EMAIL)) {
    sessionStorage.removeItem(ciamBackButton);
    sessionStorage.removeItem('isOTPGeneratedMultipleTimes');
    ciamHistory.push('/addbackupemail');
  } else if (getCallbackOfTypeSafely(finalStep, CallbackType.NameCallback)) {
    await removeSessionStorageAttributes();
    ciamHistory.push('/personalinfo');
  } else if (
    getCallbackOfTypeSafely(finalStep, CallbackType.PasswordCallback) &&
    getCallbackWithPromptSafely(finalStep, MESSAGE_ENTER_PASSWORD)
  ) {
    await removeSessionStorageAttributes();
    ciamHistory.push('/inputpassword');
  } else {
    // handle any uncaught error - request not sent from UI
    checkStep(null, 'D_704');
  }
};

const handleFinalResponse = async (
  dispatch,
  finalStep,
  history,
  gotoURI,
  locale,
  otpCount,
  isOTPGeneratedMultipleTimes,
  realm,
  brand,
  pathBuilder,
  ciamHistory,
) => {
  if (finalStep.type === StepType.LoginSuccess) {
    successRedirection(dispatch, { ciamHistory, finalStep, gotoURI, locale, realm, brand });
  } else if (otpCount > 2 && isOTPGeneratedMultipleTimes) {
    //  No more otp attempts
    await removeSessionStorageAttributes();
    updateErrorInState(dispatch, 'D_615', updateErrorCode);
    history.push(pathBuilder('/signin', true, { searchParams: { error_code: 'D_615' } }));
  } else if (getCallbackWithMessageSafely(finalStep, MESSAGE_OTP_EXPIRED)) {
    // OTP expired
    updateErrorInState(dispatch, 'D_610', updateErrorCode);
  } else if (getCallbackWithMessageSafely(finalStep, MESSAGE_RESEND_OR_RETRY)) {
    //  wrong otp entered
    updateErrorInState(dispatch, 'E_116', updateErrorCode);
  } else if (otpCount > 2 && getCallbackWithMessageSafely(finalStep, MESSAGE_TRY_NEW_OTP)) {
    // 3 attempts for 1st OTP
    updateErrorInState(dispatch, 'D_614', updateErrorCode);
  } else {
    await decideViewAfterOTPadd(dispatch, finalStep, ciamHistory);
  }
};

const otpAsyncAction = async (dispatch, payload) => {
  dispatch(updateErrorCode(null));
  const { otp, otpCount, history, gotoURI, locale, realm, brand, pathBuilder, ciamHistory } = payload;
  let { stage, isOTPGeneratedMultipleTimes } = payload;
  if (getCallbackWithMessageSafely(stage, MESSAGE_OTP_RETRY_LIMIT)) {
    await removeSessionStorageAttributes();
    updateErrorInState(dispatch, 'D_622', updateErrorCode);
    history.push(pathBuilder('/signin', true, { searchParams: { error_code: 'D_622' } }));
  }
  if (otpCount === 0) {
    stage = await handleBackButtonClick(stage, realm, brand, ciamBackButton);
  }
  isOTPGeneratedMultipleTimes = sessionStorage.getItem('isOTPGeneratedMultipleTimes');
  let resultStage = stage;
  const count = otpCount + 1;
  dispatch(updateOtpCountAction(parseInt(count, 10)));
  dispatch(updateOTPRequestedState(true));
  dispatch(changePasswordUpdateStatus(true));
  try {
    if (stage.payload?.code !== 401) {
      if (getCallbackWithMessageSafely(stage, MESSAGE_OTP_EXPIRED)) {
        const secondStep = await chooseResendOrRetryAfterOTPExpiry(dispatch, stage);
        const confirmationCallback = secondStep.getCallbackOfType(CallbackType.ConfirmationCallback);
        confirmationCallback.setInputValue(RETRY_OTP);
        const thirdStep = await nextStep(dispatch, secondStep);
        resultStage = await sendOtp(dispatch, thirdStep, otp, count);
      } else if (
        getCallbackOfTypeSafely(stage, CallbackType.TextOutputCallback) &&
        !getCallbackWithMessageSafely(stage, MESSAGE_OTP_EXPIRED)
      ) {
        const confirmationCallback = stage.getCallbackOfType(CallbackType.ConfirmationCallback);
        confirmationCallback.setInputValue(RETRY_OTP);
        const step = await nextStep(dispatch, stage);
        resultStage = await sendOtp(dispatch, step, otp, count);
      } else {
        resultStage = await sendOtp(dispatch, stage, otp, count);
      }
    }
  } finally {
    dispatch(changePasswordUpdateStatus(false));
  }
  await handleFinalResponse(
    dispatch,
    resultStage,
    history,
    gotoURI,
    locale,
    count,
    isOTPGeneratedMultipleTimes,
    realm,
    brand,
    pathBuilder,
    ciamHistory,
  );
};

export default otpAsyncAction;
