import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { BUTTON_VARIANT, P, Tooltip } from '@manulife/mux';
import {
  Form,
  FormattedRichMessage,
  FormMobileInput,
  StageShape,
  useCIAMHistory,
  useRealm,
  useCIAMContextParam,
  CoBrandingButton,
  Link,
  customStyles,
} from 'ciam-self-service-shared';
import FormElement from 'components/common/FormElement';
import useAuthTreeErrorHandler from '../views/authTree/useAuthTreeErrorHandler';
import useGoToUrl from '../../../hooks/useGoToUrl';
import { updateAddNumberLoadingStatus, updateDoItLaterLoadingStatus } from 'components/Login/actions';
import styled from 'styled-components';

export const Wrapper = styled.div`
  .custom-button-wrapper {
    padding-left: 0px;
    text-align: left;
  }
`;
function AddMobilePromptForm(props) {
  const { addNumberHandler, skipAddNumberHandler, stage, addNumberLoadingStatus, doItLaterLoadingStatus } = props;
  const [mobileNumber, setMobileNumber] = useState({ countryCode: '+1' });
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useCIAMHistory();
  const gotoURI = useGoToUrl();
  const realm = useRealm();
  const brand = useCIAMContextParam('brand');
  const { locale } = useIntl();
  const authTreeErrorHandler = useAuthTreeErrorHandler();

  const handleAddMobile = async () => {
    const mobileNumberStr = `${mobileNumber.countryCode} ${mobileNumber.phoneNumber.replace(/[+| |(|)|-]/g, '')}`;
    const payload = {
      number: mobileNumberStr,
      stage,
      history,
      brand,
    };

    try {
      dispatch(updateAddNumberLoadingStatus(true));
      await addNumberHandler(dispatch, payload);
    } catch (err) {
      authTreeErrorHandler(err);
    } finally {
      dispatch(updateAddNumberLoadingStatus(false));
    }
  };

  const handleCancelAddMobile = async () => {
    const payload = {
      stage,
      history,
      gotoURI,
      locale,
      realm,
      brand,
    };
    try {
      dispatch(updateDoItLaterLoadingStatus(true));
      await skipAddNumberHandler(dispatch, payload);
    } catch (err) {
      authTreeErrorHandler(err);
    } finally {
      dispatch(updateDoItLaterLoadingStatus(false));
    }
  };

  return (
    <>
      <Form fullWidth={false} onSubmit={handleAddMobile}>
        <P>
          <FormattedRichMessage id="signin.addmobile.whyquestion" />
          <Tooltip content={<FormattedRichMessage id="signin.addmobile.whyquestion.toolTip" />} />
        </P>
        <FormMobileInput
          id="mobile-number"
          label={intl.formatMessage({ id: 'shared.addmobile.label' })}
          value={mobileNumber}
          onChange={setMobileNumber}
          required
          ariaRequired
          autoFocus
        />
        <FormElement spacing={2}>
          <CoBrandingButton
            type="submit"
            variant={BUTTON_VARIANT.PRIMARY}
            customStyle={customStyles.button}
            disabled={addNumberLoadingStatus}
            saving={addNumberLoadingStatus}
          >
            <FormattedMessage id="signin.addmobile.submitButton" />
          </CoBrandingButton>
        </FormElement>

        <FormElement spacing={1}>
          <Wrapper>
            <CoBrandingButton
              id="skip-button"
              as={Link}
              className="custom-button-wrapper"
              variant={BUTTON_VARIANT.TERTIARY}
              onClick={handleCancelAddMobile}
              disabled={doItLaterLoadingStatus}
              saving={doItLaterLoadingStatus}
            >
              <FormattedMessage id="signin.addmobile.doitlaterButton" />
            </CoBrandingButton>
          </Wrapper>
        </FormElement>
      </Form>
    </>
  );
}

AddMobilePromptForm.propTypes = {
  addNumberHandler: PropTypes.func.isRequired,
  skipAddNumberHandler: PropTypes.func.isRequired,
  stage: StageShape.isRequired,
  addNumberLoadingStatus: PropTypes.bool,
  doItLaterLoadingStatus: PropTypes.bool,
};

export default AddMobilePromptForm;
