import Page, { AUTH_TYPE } from 'components/common/Page';
import { FormattedMessage, useIntl } from 'react-intl';
import { BUTTON_VARIANT, H2 } from '@manulife/mux';
import {
  ContentPage,
  FormattedRichMessage,
  CoBrandingButton,
  customStyles,
  useCIAMHistory,
  useRealm,
  usePathBuilder,
  useCIAMContextParam,
} from 'ciam-self-service-shared';
import MFAEnterCode from 'components/common/MFA/MFAEnterCode';
import userContextUtils from 'utils/userContextUtils';
import { useSelector, useDispatch } from 'react-redux';
import FormElement from 'components/common/FormElement';
import { updateBackupEmailLoadingStatus, updateGoBackLoadingStatus } from 'components/Login/actions';
import { useHistory } from 'react-router';
import useAuthTreeErrorHandler from 'components/AddBackupEmail/authTree/useAuthTreeErrorHandler';
import goBackHandlerAsyncAction from 'utils/loginAuthnTree/goBackHandlerAsyncAction';
import resendForAddNumberOrBackupEmailAsyncAction from 'utils/loginAuthnTree/resendForAddNumberOrBackupEmailAsyncAction';
import handleOtpForAddNumberOrBackupEmailAsyncAction from 'utils/loginAuthnTree/handleOtpForAddNumberOrBackupEmailAsyncActionImpl';

const AddBackupEmailMFA = () => {
  const intl = useIntl();
  const stage = useSelector((state) => state.loginInfo.stage);
  const otpCount = useSelector((state) => state.loginInfo.otpCount);
  const isOTPGeneratedMultipleTimes = useSelector((state) => state.loginInfo.isOTPGeneratedMultipleTimes);
  const loginError = useSelector((state) => state.loginInfo.loginError);
  const backupEmail = useSelector((state) => state.loginInfo.backupEmail);
  const enterCodeInProgress = false;
  const goBackInProgress = false;
  const dispatch = useDispatch();
  const ciamHistory = useCIAMHistory();
  const realm = useRealm();
  const history = useHistory();
  const pathBuilder = usePathBuilder();
  const brand = useCIAMContextParam('brand');
  const authTreeErrorHandler = useAuthTreeErrorHandler();

  const goBack = async () => {
    const payload = {
      stage,
      history,
      realm,
      brand,
      ciamHistory,
      pathBuilder,
    };
    try {
      dispatch(updateGoBackLoadingStatus(true));
      await goBackHandlerAsyncAction(dispatch, payload);
    } catch (err) {
      authTreeErrorHandler(err);
    } finally {
      dispatch(updateGoBackLoadingStatus(false));
    }
  };

  const handleSubmitCode = async (_, payload) => {
    try {
      dispatch(updateBackupEmailLoadingStatus(true));
      await handleOtpForAddNumberOrBackupEmailAsyncAction(dispatch, payload, 'backupEmail');
    } catch (err) {
      authTreeErrorHandler(err);
    } finally {
      dispatch(updateBackupEmailLoadingStatus(false));
    }
  };

  const handleRequestResend = async (_, payload) => {
    try {
      await resendForAddNumberOrBackupEmailAsyncAction(dispatch, payload, 'backupEmail');
    } catch (err) {
      authTreeErrorHandler(err);
    }
  };

  return (
    <Page title={intl.formatMessage({ id: 'mfa.enterCode.page.title' })} authType={AUTH_TYPE.UNAUTHENTICATED}>
      <ContentPage heading={<FormattedRichMessage id="signin.addbackupemail.enterCode.heading" />}>
        <MFAEnterCode
          channel={userContextUtils.getChannels({ channelList: ['Secondary email'], secondaryMail: backupEmail })}
          onSubmitCode={handleSubmitCode}
          singleChannel
          stage={stage}
          onRequestResend={handleRequestResend}
          otpCount={otpCount}
          hasResentOtp={isOTPGeneratedMultipleTimes}
          error={loginError}
          isLoading={enterCodeInProgress}
          content={
            <FormattedRichMessage
              id="signin.addbackupemail.enterCode.timeReminder"
              values={{ secondaryMail: backupEmail }}
            />
          }
        />
        <H2>
          <FormattedMessage id="signin.addbackupemail.didnotgetcode.title" />
        </H2>
        <FormattedRichMessage id="signin.addbackupemail.didnotgetcode.text" />
        <FormElement spacing={2}>
          <CoBrandingButton
            variant={BUTTON_VARIANT.SECONDARY}
            customStyle={customStyles.button}
            onClick={goBack}
            disabled={goBackInProgress}
            saving={goBackInProgress}
            id="back-btn"
          >
            <FormattedMessage id="signin.addbackupemail.gobackButton" />
          </CoBrandingButton>
        </FormElement>
      </ContentPage>
    </Page>
  );
};

export default AddBackupEmailMFA;
